import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PreventBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Interceptar la navegación hacia atrás
    const handlePopState = (event) => {
      // Cambiar la ruta sin añadir al historial
      navigate("/", { replace: true });
    };

    // Añadir un "listener" para el evento popstate
    window.history.pushState(null, "", window.location.href); // Añadir una entrada al historial inicial
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Limpiar el listener cuando el componente se desmonte
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return null; // Este componente no renderiza nada
};

export default PreventBackButton;
