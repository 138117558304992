
const Projects = () => {
    return (
      <section id="trabajos" className="bg-gray-100 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-extrabold text-gray-800 mb-8 text-center">Nuestros Servicios</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white rounded-lg overflow-hidden shadow-md">
              <div className="h-64 bg-gray-200" style={{backgroundImage: 'url(/assets/photos/10.jpeg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                {/* Imagen del proyecto 1 */}
              </div>
              <div className="p-6" >
                <h3 className="font-semibold text-xl mb-2">Especialistas en aluminio y vidrio</h3>
                <p className="text-gray-600">Deja que tus ideas cobren vida. ¡Remodelamos con calidad y estilo!.</p>
              </div>
            </div>
            
            <div className="bg-white rounded-lg overflow-hidden shadow-md">
              <div className="h-64 bg-gray-200" style={{backgroundImage: 'url(/assets/photos/07.jpeg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                {/* Imagen del proyecto 2 */}
              </div>
              <div className="p-6" >
                <h3 className="font-semibold text-xl mb-2">Servicios de acabados</h3>
                <p className="text-gray-600">¡Consulta gratuita! Cuéntanos tu idea y hazla realidad.</p>
              </div>
            </div>

            <div className="bg-white rounded-lg overflow-hidden shadow-md">
              <div className="h-64 bg-gray-200" style={{backgroundImage: 'url(/assets/photos/38.jpeg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                {/* Imagen del proyecto 3 */}
              </div>
              <div className="p-6">
                <h3 className="font-semibold text-xl mb-2">Mantenimiento Empresarial</h3>
                <p className="text-gray-600">Experiencia, calidad y compromiso en cada proyecto.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    );
  };
  
  export default Projects;