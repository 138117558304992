import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import AboutUs from "./components/AboutUs";
import Projects from "./components/Projects";
import ContactForm from "./components/ContactForm";
import Services from "./components/Services";
import Footer from "./components/Footer";
import PreventBackButton from './components/PreventBackButton'; 

const App = () => (
  <Router>
    <PreventBackButton />
    <div className="min-h-screen bg-gray-50">
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <section id="nosotros">
                <AboutUs />
              </section>
              <section id="trabajos">
                <Projects />
              </section>
              <section id="contacto">
                <ContactForm />
              </section>
            </>
          }
        />
        <Route path="/servicios" element={<Services />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;


