import React from 'react';
import { FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => (
  <footer className="bg-gray-900 text-white py-8">
    <div className="container mx-auto px-4">
      <div className="grid md:grid-cols-3 gap-8">
        <div>
          <h4 className="text-xl font-bold mb-4">GRUPO TERE, S.A. DE C.V.</h4>
          <p className="text-gray-400">
            Transformando espacios, construyendo sueños.<br />
          </p>
          <p className="text-gray-400">
            Comprometidos con la excelencia en cada proyecto. <br /><br />
            En Grupo Tere, nuestra misión es transformar espacios y construir confianza a través de un servicio personalizado que combine dinamismo, calidad y eficiencia.
          </p>
        </div>
        <div>
          
          {/* Mapa de Ubicación */}
          <div>
              <h3 className="text-xl font-semibold mb-4">Nuestra Ubicación</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3258.745124288698!2d-89.16895222211924!3d13.759391707897981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6339e30d6c0cb5%3A0x7312bfc864b5a962!2sGRUPO%20TERE%20S.A.%20DE%20C.V.!5e0!3m2!1ses!2ssv!4v1738128170385!5m2!1ses!2ssv"
                width="90%"
                height="200"
                style={{ border: '2px solid #007BFF', borderRadius: '8px' }} 
                allowFullScreen=""
                loading="lazy"
              />
            </div>
        </div>
        <div>
          <h4 className="text-xl font-bold mb-4">Síguenos</h4>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/profile.php?id=100095330002255" className="text-gray-400 hover:text-white"
            target="_blank" rel="noopener noreferrer">
              <FaFacebook size={24} />
            </a>
            <a href="https://www.instagram.com/grupo_tere/" className="text-gray-400 hover:text-white"
            target="_blank" rel="noopener noreferrer">
              <FaInstagram size={24} />
            </a>
            <a href="https://wa.me/50374526564" className="text-gray-400 hover:text-white"
            target="_blank" rel="noopener noreferrer">
              <FaWhatsapp size={24} />
            </a>
          </div>
          <div>
          <p className="text-gray-400">
          <br /><br />Mantente informado sobre<br />
            nuestro trabajo siguiéndonos<br />
            en nuestras redes sociales.
          </p>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-800 mt-3 pt-1 text-center text-gray-400">
        <p>&copy; 2025 GRUPO TERE, S.A. DE C.V. Todos los derechos reservados.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
