import React, { useRef } from 'react';
import { FaWhatsapp, FaFacebook, FaInstagram, FaEnvelope, FaPhone } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // Evita que la página se recargue

    emailjs
      .sendForm(
        'service_42bc7ru', // Reemplaza con tu Service ID
        'template_az512nr', // Reemplaza con tu Template ID
        form.current, // Se obtiene el formulario como referencia
        'zlV-gHb90kBH8jcOp' // Reemplaza con tu User ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Mensaje enviado correctamente');
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          alert('Hubo un error al enviar el mensaje');
        }
      );
  };

  return (
    <section id="contacto" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-8 text-center">Contáctanos</h2>
        <div className="max-w-7xl mx-auto rounded-lg relative overflow-hidden">
          {/* Imagen de fondo con transparencia */}
          <div
            className="absolute inset-0 rounded-lg"
            style={{
              backgroundImage: "url('/assets/images/contact.jpg')", 
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.6, 
              filter: "brightness(1.2) contrast(1.1)", 
              zIndex: 0, 
            }}
          ></div>

          {/* Contenido visible */}
          <div className="grid md:grid-cols-4 gap-8 relative z-10 p-8 bg-gradient-to-t from-white via-transparent to-transparent rounded-lg">
            <div></div>

            {/* Formulario de Contacto */}
            <div>
              <form ref={form} onSubmit={sendEmail} className="space-y-4">
                <div>
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Nombre"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Correo electrónico"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
                <div>
                  <textarea
                    name="message"
                    placeholder="Mensaje"
                    rows="4"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-blue-800 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 w-full"
                >
                  Enviar Mensaje
                </button>
              </form>
            </div>

            {/* Información de Contacto */}
            <div>
              <h3 className="text-xl font-semibold mb-4">Información de Contacto</h3>
              <div className="space-y-4">
                <div className="flex items-center">
                  <FaPhone className="mr-2" />
                  <span>+ 503 7452 - 6564</span>
                </div>
                <div className="flex items-center">
                  <FaEnvelope className="mr-2" />
                  <span>grupotereventas@gmail.com</span>
                </div>
                <div className="flex space-x-4 mt-6">
                  <a
                    href="https://www.facebook.com/profile.php?id=100095330002255"
                    className="text-blue-800 hover:text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook size={24} />
                  </a>
                  <a
                    href="https://www.instagram.com/grupo_tere/"
                    className="text-blue-800 hover:text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram size={24} />
                  </a>
                  <a
                    href="https://wa.me/50374526564"
                    className="text-blue-800 hover:text-blue-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={24} />
                  </a>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;

