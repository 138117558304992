import React from 'react';

const AboutUs = () => {
  return (
    <section id="nosotros" className="py-20 bg-gray-50">
  <div className="container mx-auto px-6 md:px-12">
    <div className="grid md:grid-cols-2 gap-16 items-center">
      {/* Text Content */}
      <div>
        <h2 className="text-3xl font-extrabold text-gray-800 mb-8">Sobre Nosotros</h2>
        <div className="mb-10">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Misión</h3>
          <p className="text-gray-600 leading-relaxed">
            Ofrecer a nuestros clientes una atención personalizada, dinámica y satisfactoria, en el área de mantenimiento y construcción, 
            con calidad y eficiencia en el tiempo, adecuándonos con las nuevas tendencias del mercado, buscando soluciones confiables a nuestros 
            clientes y que nos permitan ayudar a solventar y prevenir sus inconvenientes con las normas de seguridad ocupacional o industrial.
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Visión</h3>
          <p className="text-gray-600 leading-relaxed">
            Lograr ser una empresa reconocida en el mercado nacional, con un prestigio y un posicionamiento en la calidad de nuestros 
            productos y servicios, crecer como una empresa en valores para el desarrollo de nuestros colaboradores y la sociedad. 
          </p>
        </div>
        <div>
          <h3 className="text-2xl font-semibold text-gray-700 mb-4">Valores</h3>
          <ul className="list-disc list-inside text-gray-600 leading-relaxed">
            <li>Credibilidad</li>
            <li>Honestidad</li>
            <li>Garantías</li>
            <li>Responsabilidad</li>
            <li>Eficiencia</li>
            <li>Trabajo en equipo</li>
            <li>Seguridad e integridad</li>
          </ul>
        </div>
      </div>

      {/* Image Section */}
      <div 
        className="h-96 rounded-xl shadow-lg overflow-hidden" 
        style={{
          backgroundImage: 'url(/assets/images/sobrenos.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
        {/* Placeholder for background image */}
      </div>
    </div>
  </div>
</section>

  );
};

export default AboutUs;
