import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import "../styles/global.css";
import ImageGallery from "react-image-gallery";

const projects = [
  {
    id: 1,
    title: "Mantenimiento de Estructuras",
    description: "Se brindó servicios de mantenimiento y reparación para ISSS.",
    images: [
      "/assets/photos/01.jpeg",
      "/assets/photos/02.jpeg",
      "/assets/photos/03.jpeg",
      "/assets/photos/04.jpeg",
      "/assets/photos/05.jpeg",
    ],
  },
  {
    id: 2,
    title: "Instalación de cielo falso",
    description: "Instalamos cielo falso en una habitación del ISSS.",
    images: [
      "/assets/photos/06.jpeg", 
      "/assets/photos/07.jpeg"],
  },
  {
    id: 3,
    title: "Instalación de puertas",
    description: "Proporcionamos nuestros servicios en reparaciones de puertas.",
    images: [
      "/assets/photos/08.jpeg",
      "/assets/photos/09.jpeg",
      "/assets/photos/10.jpeg",
      "/assets/photos/42.jpeg",
      "/assets/photos/43.jpeg",
      "/assets/photos/44.jpeg",
      "/assets/photos/45.jpeg",
    ],
  },
 
  {
    id: 4,
    title: "Remodelaciones en general",
    description:
      "Remodelaciones con un diseño contemporáneo.",
    images: [
      "/assets/photos/11.jpeg",
      "/assets/photos/27.png",
      "/assets/photos/28.png",
      "/assets/photos/15.png",
      "/assets/photos/16.png",
      "/assets/photos/17.png",
      "/assets/photos/29.png",
      "/assets/photos/30.png",
      "/assets/photos/31.png",
      "/assets/photos/32.png",
      "/assets/photos/33.jpeg",
      "/assets/photos/34.jpeg",
      "/assets/photos/35.jpeg",
      "/assets/photos/36.jpeg",
      "/assets/photos/37.jpeg",
      "/assets/photos/38.jpeg",
      "/assets/photos/39.jpeg",
    ],
  },
  {
    id: 5,
    title: "Construcción de cubículos",
    description: "Modernizamos la fachada para un look elegante y sofisticado.",
    images: [
      "/assets/photos/18.png",
      "/assets/photos/19.png",
      "/assets/photos/20.png",
      "/assets/photos/21.png",
      "/assets/photos/22.png",
    ],
  },
  {
    id: 6,
    title: "Cambio de piso",
    description: "Realizamos cambios de piso con excelencia.",
    images: [
      "/assets/photos/13.jpeg",
      "/assets/photos/14.jpeg",
      "/assets/photos/23.png", 
      "/assets/photos/24.png",
      "/assets/photos/40.jpeg", 
      "/assets/photos/41.jpeg"
    ],
  },
  {
    id: 7,
    title: "Instalación de puertas corredizas",
    description: "Proporcionamos nuestros servicios en instalación de puertas corredizas.",
    images: [
      "/assets/photos/25.png"],
  },
];

const Services = () => {
  const imageItems = projects.flatMap((project) =>
    project.images.map((image, index) => ({
      original: image,
      thumbnail: image,
      description: `${project.title}`,
    }))
  );

  return (
    <main className="pt-24 pb-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-8">
          Nuestros Proyectos
        </h2>
        <p className="text-center text-gray-600 mb-12">
          Mira algunos de nuestros proyectos transformados con dedicación y
          experiencia.
        </p>
        <ImageGallery
          items={imageItems}
          showFullscreenButton={true}
          showPlayButton={false}
        />
      </div>
    </main>
  );
};

export default Services;
