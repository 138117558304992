import React from 'react';


const HeroSection = () => {
  return (
    <section id="inicio" className="pt-20 bg-blue-800 text-white" style={{backgroundImage: 'url(/assets/images/banner.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}} >
      <div className="container mx-auto px-4 py-32">
        <div className="max-w-3xl">
          <h1 className="text-5xl font-bold mb-6">Soluciones inteligentes en el mantenimiento de tu empresa</h1>
          <p className="text-xl mb-8">Ademas, somos Especialistas en remodelación y construcción con más de 15 años de experiencia</p>
          <a href="#contacto" className="bg-white text-blue-800 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100">
            Cotiza tu proyecto
          </a>
        </div>
      </div>
    </section>

  );
};

export default HeroSection;
