import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";
import { Menu, X } from "lucide-react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleScrollToSection = (sectionId) => {
    navigate("/"); // Cambia a la ruta principal si no estás en ella
    setTimeout(() => {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 100); // Espera un poco para que React Router cargue la página
    setIsMenuOpen(false); // Cierra el menú después de hacer clic
  };

  return (
    <header className="bg-white shadow-md fixed w-full top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div className="flex items-center space-x-4">
            <img
              src="/assets/images/logo.png"
              alt="Logo de Grupo Tere"
              className="h-12 w-12 object-contain"
            />
            <div className="text-3xl font-bold text-gray-800 mb-1">
              GRUPO TERE, S.A. DE C.V.
            </div>
          </div>

          {/* Menú toggle en móvil */}
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Menú principal en pantallas grandes */}
          <nav className="hidden md:flex space-x-8">
            <Link
              to="/"
              className="text-gray-600 hover:text-blue-800 font-bold"
              onClick={() => scroll.scrollToTop()}
            >
              Inicio
            </Link>
            <button
              className="text-gray-600 hover:text-blue-800 font-bold"
              onClick={() => handleScrollToSection("nosotros")}
            >
              Nosotros
            </button>
            <Link
              to="/servicios"
              className="text-gray-600 hover:text-blue-800 font-bold"
              onClick={() => scroll.scrollToTop()}
            >
              Proyectos
            </Link>
            <button
              className="text-gray-600 hover:text-blue-800 font-bold"
              onClick={() => handleScrollToSection("trabajos")}
            >
              Servicios
            </button>
            <button
              className="text-gray-600 hover:text-blue-800 font-bold"
              onClick={() => handleScrollToSection("contacto")}
            >
              Contacto
            </button>
          </nav>
        </div>

        {/* Menú responsivo en pantallas pequeñas */}
        {isMenuOpen && (
          <div className="md:hidden bg-white pb-4">
            <ul className="flex flex-col space-y-4">
              <li>
                <Link
                  to="/"
                  className="text-gray-600 hover:text-blue-800 font-bold block"
                  onClick={() => {
                    scroll.scrollToTop();
                    setIsMenuOpen(false); // Cierra el menú
                  }}
                >
                  Inicio
                </Link>
              </li>
              <li>
                <button
                  className="text-gray-600 hover:text-blue-800 font-bold block"
                  onClick={() => handleScrollToSection("nosotros")}
                >
                  Nosotros
                </button>
              </li>
              <li>
                <Link
                  to="/servicios"
                  className="text-gray-600 hover:text-blue-800 font-bold block"
                  onClick={() => {
                    scroll.scrollToTop();
                    setIsMenuOpen(false); 
                  }} 
                >
                  Proyectos
                </Link>
              </li>
              <li>
                <button
                  className="text-gray-600 hover:text-blue-800 font-bold block"
                  onClick={() => handleScrollToSection("trabajos")}
                >
                  Servicios
                </button>
              </li>
              <li>
                <button
                  className="text-gray-600 hover:text-blue-800 font-bold block"
                  onClick={() => handleScrollToSection("contacto")}
                >
                  Contacto
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
